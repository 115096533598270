.Nav {
  display: none;
  text-align: right;

  @include bp(md) {
    display: inline-block !important;
    float: right;
  }
}

.Nav__list {
  padding: 0;
  margin-bottom: 0;
}

.Nav__item {
  list-style: none;
  display: inline-block;
  width: 100%;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid white;

  &:last-child {
    border-bottom: none;
  }

  @include bp(md) {
    margin-left: 10px;
    width: auto;
    font-size: 18px;
    font-weight: 400;
    border-bottom: none;
  }

  ul {
    list-style: none;

    @include bp(md) {
      display: none;
    }

    li {
      font-weight: 400;
    }
  }
}

.Nav__link {
  padding: 10px 15px;
  display: block;
  will-change: box-shadow, padding-top;
  color: $white;

  &:hover,
  &:focus {
    color: $white;
  }

  @include bp(md) {
    padding: 10px;
    background-color: $white;
    border-radius: 5px 5px 0 0;
    color: $primary;

    &:hover,
    &:focus {
      color: $primary;
    }
  }
}

.MenuButton {
  background: none;
  border: 0;
  float: right;
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.MenuButton__line-1,
.MenuButton__line-2,
.MenuButton__line-3 {
  opacity: 1;
  top: 0;
  transform: translateX(0);
  transition: opacity .3s, transform .5s, top .5s;
  will-change: opacity, transform, top;
  width: 45px;
  height: 3px;
  background-color: $white;
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.MenuButton--open {
  .MenuButton__line-1 {
    transform: rotateZ(45deg);
    top: 13px;
  }

  .MenuButton__line-2 {
    opacity: 0;
    transform: translateX(20px);
  }

  .MenuButton__line-3 {
    transform: rotateZ(-45deg);
    top: -13px;
  }
}
