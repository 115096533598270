.Footer {
  color: $white;
  padding: 20px 0;
  background: $blueGrey;
}

.Footer__social {
  margin: 0 5px;

  &:hover {
    text-decoration: none;
  }
}

.Footer_social-icon {
  width: 40px;
}

.Footer__logo {
  max-width: 200px;
}
