.Header {
  padding: 5px 0 0;
  background-color: rgba(0, 128, 116, .8);
  transition: background-color .5s ease-in-out;
  will-change: background-color;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 0 10px $black;

  @include bp(md) {
    min-height: 83px;
  }
}

.Header--menu-open {
  background-color: $primary;

  @include bp(md) {
    background-color: rgba(0, 128, 116, .8);
  }
}

.Header__logo {
  max-width: 185px;

  @include bp(md) {
    margin-top: 10px;
    max-width: 175px;
  }

  @include bp(lg) {
    margin-top: 0;
    max-width: 225px;
  }
}
