.Hero {
  box-shadow: inset 0 0 5px $black;
  padding: 200px 0 100px 0;
  background-image: linear-gradient(135deg, $secondary, $primary);
  background-repeat: no-repeat;
  background-attachment: initial;
  background-size: cover;
  color: $white;
  text-shadow: 0 0 5px $blueGrey;
  position: relative;
}

.Hero--fixed {
  background-attachment: fixed;
}

.Hero__overlay {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .5), rgba(0, 0, 0, .1));
  }
}

.Hero__title {
  margin-bottom: 30px;
  background-color: rgba(0, 0, 0, .7);
  display: inline-block;
  padding: 0 10px 15px 10px;
  line-height: 1;

  @include bp(sm, 'max') {
    font-size: 50px;
  }
}

.Hero__lead {
  background-color: rgba(0, 0, 0, .7);
  display: inline-block;
  padding: 10px;
  line-height: 1.2;
}

.Hero__link {
  color: $white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: inherit;
  }
}
