.Accordion__header {
  background-color: $blueGrey;
}

.Accordion__title {
  color: $white;
  font-size: 18px;
  padding: 0;
  font-weight: 500;

  &:hover {
    color: $white;
  }
}

.Accordion__body {
  background-color: $white;
}
