// sass-lint:disable variable-name-format
@mixin bp($size, $max: '') {
  @if $max == max {
    @media screen and (max-width: map-get($grid-breakpoints, $size) - 1px) {
      @content;
    }
  } @else {
    @media screen and (min-width: map-get($grid-breakpoints, $size)) {
      @content;
    }
  }
}
