.Gallery {
  font-size: 0;
}

.Gallery__link {
  text-decoration: none;
  display: inline-block;
  transition: transform .2s ease-in-out;
  width: 50%;

  @include bp(sm) {
    width: 25%;
  }

  @include bp(md) {
    width: 20%;
  }

  @include bp(lg) {
    width: 16.6666666666666%;
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    transform: scale(1.1);
  }
}

.Gallery__thumb {
  padding: 5px;

  @include bp(sm) {
    padding: 10px;
  }
}
