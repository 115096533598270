// sass-lint:disable variable-name-format
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  sm: 768px,
  md: 992px,
  lg: 1240px,
  xl: 1280px
);
