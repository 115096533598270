body {
  font-family: 'Lato', sans-serif;
}

a {
  color: $primary;
}

img {
  max-width: 100%;
}

.background--white {
  background-color: $white;
}

.background--light-grey {
  background-color: $gray-100;
}

.background--grey {
  background-color: $gray-200;
}

.background--primary {
  background-color: $primary;
}

.background--secondary {
  background-color: $secondary;
}

.background--sky {
  background-image: url('/assets/img/backgrounds/background-sky.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.error {
  display: none;
}

.btn-primary {
  background-color: $primary;
  border: 0;

  &:hover,
  &:focus {
    background-color: $primary;
  }
}
